import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LoaderWidget, RootProvider } from '@jarvis/react-portal-addons';
import Config from './Config';
import { getLDClientSideID, getUniqueUserKey } from './utils/ldClient';
import resources from './assets/locale/index';

const PrintersStatusWidget = lazy(() => (
  import(/* webpackPreload: true */ './components/PrintersStatusWidget')
));
const App = lazy(() => import(/* webpackPrefetch: true */ './App'));

const PrintersMFE = ({ type, ...rest }) => {
  const {
    properties,
    shell,
    stack,
  } = rest;

  const localization = shell.v1?.localization;

  const [Container, setContainer] = useState(() => ({ children }) => children);
  const [fetchingLD, setFetchingLD] = useState(true);

  const getComponent = useCallback(props => {
    switch (type) {
      case 'SMBPrintersStatusWidget':
        return <PrintersStatusWidget {...props} />;
      default:
        return <App {...props} />;
    }
  }, [type]);

  useEffect(() => {
    Config.add('smartSecuritySolutionDataId', properties.smartSecuritySolutionDataId);
  }, [properties.smartSecuritySolutionDataId]);

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const LDProvider = await asyncWithLDProvider({
          clientSideID: getLDClientSideID(),
          options: {
            bootstrap: 'localStorage',
            streaming: false,
          },
          reactOptions: {
            useCamelCaseFlagKeys: true,
          },
          user: {
            key: getUniqueUserKey(localization?.locale),
          },
        });
        setContainer(() => LDProvider);
      } catch (err) {
        console.error(err);
      } finally {
        setFetchingLD(false);
      }
    };
    fetchFetureFlags();
  }, [localization?.locale]);

  if (fetchingLD) {
    return <LoaderWidget fullScreen />;
  }

  return (
    <Container>
      <RootProvider
        localization={localization}
        resources={resources}
        shell={shell}
        stack={stack}
      >
        <Suspense fallback={<LoaderWidget fullScreen />}>
          {getComponent(rest)}
        </Suspense>
      </RootProvider>
    </Container>
  );
};

PrintersMFE.defaultProps = {
  properties: {},
  shell: {},
  type: null,
};

PrintersMFE.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  shell: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

export default PrintersMFE;
