class Config {
  constructor() {
    this.data = {};
  }

  add(key, value) {
    this.data[key] = value;
  }

  get(key) {
    return this.data[key];
  }
}

const instance = new Config();
Object.freeze(instance);

export default instance;
