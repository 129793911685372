import React from 'react';
import { propOr } from 'ramda';
import PrintersMFE from '../src';
import pkg from '../package.json';
import { namespace } from './configs/projectNames';

/**
 * @function Root Main function
 * @param props
 * @returns
 */
const Root = props => {
  const shellProps = window.Shell || {};
  const type = propOr('', 'type', props);

  return (
    <section
      id={pkg.name}
      className={namespace}
      style={{
        height: '100%',
        ...(type ? {} : { minHeight: 'calc(100vh - 176px)' }),
      }}
    >
      <PrintersMFE shell={shellProps} {...props} />
    </section>
  );
};

export default Root;
